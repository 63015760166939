<template>
	<div>
		<p>Home</p>
        <router-view />
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
